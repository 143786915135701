/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.mobilesiteheader {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.mobilesiteheader__logo {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  z-index: 1;
}

@media print {
  .mobilesiteheader {
    display: none !important;
  }
}
@media (min-width: 900px) {
  .mobilesiteheader {
    display: none;
  }
}
@media (max-width: 899px) {
  .siteheader {
    display: none;
  }
}
.mobilesiteheader__togglemenu {
  margin-left: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  cursor: pointer;
}

.mobilesiteheader__togglemenu {
  color: #D00057;
}

svg.mobilesiteheader__togglemenu__icon {
  fill: #D00057;
}

/* MOBILE */
@media (max-width: 599px) {
  .mobilesiteheader__logo {
    height: 45px;
    margin-top: 5px;
    margin-bottom: -5px;
  }
  .mobilesiteheader__togglemenu {
    font: 500 15px "Raleway", sans-serif;
    margin-top: auto;
    margin-bottom: auto;
  }
  .mobilesiteheader__togglemenu__icon {
    margin-left: 11px;
    width: 16px; /* [IEFIX] */
    height: 16px;
  }
}
/* TABLET */
@media (min-width: 600px) {
  .mobilesiteheader__logo {
    height: 94px;
    margin-top: 10px;
    margin-bottom: -10px;
  }
  .mobilesiteheader__togglemenu {
    font: 500 18px "Raleway", sans-serif;
    -webkit-align-self: flex-end;
    align-self: flex-end;
    margin-bottom: 16px;
  }
  .mobilesiteheader__togglemenu__icon {
    margin-left: 11px;
    width: 24px; /* [IEFIX] */
    height: 24px;
  }
}