.photoalbum {
  margin-top: 20px;
}
.photoalbum .thumblist {
  list-style-type: none;
  margin: 0 0 0 -10px;
  padding: 0;
  display: block;
}
.photoalbum .thumblist:after {
  clear: both;
  content: "";
  display: block;
}
.photoalbum .thumblist > .image {
  float: left;
  width: calc(20% - 10px);
  margin: 0 0 10px 10px;
  background-color: #f1f1f1;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}
.photoalbum .thumblist > .image:after {
  content: "";
  display: block;
  padding-top: 100%;
}
.photoalbum .thumblist > .image > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.15s, opacity 0.2s;
}
.photoalbum .thumblist > .image.loaded > img {
  opacity: 1;
}
.photoalbum .thumblist > .image:hover > img {
  transform: scale(1.1, 1.1);
}
@media (max-width: 500px) {
  .photoalbum .thumblist > .image {
    width: calc(25% - 10px);
  }
}