/* based on the menu-listtree DOM */
/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.slidemenu__header {
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;
  -ms-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #D00057;
  color: #FFFFFF;
  text-align: left;
  padding: 20px;
}

.slidemenu__searchbar {
  padding: 20px 20px 30px 20px;
}

.slidemenu__searchbar label {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #FFFFFF;
}

/* .slidemenu__searchicon */
.slidemenu__searchbar svg {
  width: 20px; /* [IEFIX] */
  height: 20px;
  fill: #FFFFFF;
  margin-right: 15px;
}

.slidemenu__searchinput.slidemenu__searchinput {
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font: 300 15px "Raleway", sans-serif;
  padding: 0;
  background-color: transparent;
  border: 0;
  color: #FFFFFF;
}

.slidemenu__searchinput.slidemenu__searchinput:focus {
  outline: 0;
}

.slidemenu__searchinput::-webkit-input-placeholder {
  color: #e9ccd8;
}

.slidemenu__searchinput::-moz-placeholder { /* Firefox 19+ */
  color: #e9ccd8;
}

.slidemenu__searchinput:-ms-input-placeholder {
  color: #e9ccd8;
}

#slidemenu-close {
  margin-left: auto;
  width: 18px;
  height: 18px;
  background-image: url("../../web/img/close-white.svg");
  background-size: cover;
  cursor: pointer;
}

#slidemenu .listtreemenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

#slidemenu .listtreemenu a {
  text-decoration: none;
  color: #e9ccd8;
}

#slidemenu .listtreemenu a.selected {
  font-weight: 700;
}

#slidemenu .listtreemenu a:hover,
#slidemenu .listtreemenu a:active,
#slidemenu .listtreemenu a.selected {
  color: #FFFFFF;
}

#slidemenu .listtreemenu--depth1 > li > a {
  display: block;
  padding-top: 9px;
  padding-bottom: 7px;
  font: 300 18px "Raleway", sans-serif;
  padding-left: 20px;
  padding-right: 20px;
}

#slidemenu .listtreemenu--depth2 {
  padding-bottom: 7px; /* to increase the whitespace of the last depth2 item to the size of padding-bottom of a depth1 item */
}

#slidemenu .listtreemenu--depth2 > li > a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  font: 300 15px Arial;
  padding-left: 20px;
  padding-right: 20px;
}

#slidemenu .listtreemenu--depth3 > li > a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13.5px;
  padding-left: 35px;
  padding-right: 35px;
}

#slidemenu .listtreemenu--depth2 > li > a::before,
#slidemenu .listtreemenu--depth3 > li > a::before {
  display: inline-block;
  content: "-";
  margin-right: 5px;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}