.wh-form__buttongroup {
  text-align: right;
  padding: 30px 0 20px;
  margin-top: 30px;
  border-top: 1px solid #C5C5C5;
}
.wh-form__buttongroup::after {
  content: "";
  display: block;
  clear: both;
}
.wh-form__buttongroup .wh-form__button {
  min-width: 200px;
}
.wh-form__buttongroup .wh-form__button:hover {
  opacity: 0.8;
}
.wh-form__buttongroup .wh-form__button + .wh-form__button {
  margin-left: 15px;
}
.wh-form__buttongroup .wh-form__button--previous {
  float: left;
  border: 1px solid var(--wh-button-background-color);
  color: var(--wh-button-background-color);
  text-align: right;
  font-weight: 600;
}
.wh-form__buttongroup .wh-form__button--previous::after {
  order: -1;
  font-weight: 400;
  content: "\f104";
  margin-left: 0;
  margin-right: 10px;
}

.wh-form__button {
  text-decoration: none;
  color: inherit;
  font: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: var(--wh-button-border-radius);
  height: var(--wh-button-height);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  line-height: 20px;
  padding: 14px 15px;
  background-color: var(--wh-button-background-color);
  color: #fff;
  text-align: left;
  transition: background-color 0.3s;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  border: 0 none;
}
.wh-form__button .wh-form__buttonlabel {
  flex: 1;
}
.wh-form__button::after {
  display: inline-block;
  flex: none;
  content: "\f105";
  font-size: 20px;
  margin-left: 10px;
  font-family: "FontAwesome";
}

.wh-form__button--previous {
  box-shadow: none;
  background-color: transparent;
  border: 1px solid #fff;
  position: relative;
}
.wh-form__button--previous .wh-form__buttonlabel, .wh-form__button--previous .button__label {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wh-form__button--previous::before {
  content: "";
  display: block;
  position: absolute;
  border-radius: var(--wh-field-border-radius);
  background-color: rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.wh-form__button--previous:hover::before {
  opacity: 1;
}