:root {
  --wh-field-textinput-height: 44px;
  --wh-field-textarea-height: 140px;
  --wh-field-radiocheckbox-size: 24px;
  --wh-label-font-size: 15px;
  --wh-label-width: 195px;
  --wh-button-background-color: #D00057;
  --wh-button-border: 0 none;
  --wh-button-color: #fff;
  --wh-button-padding: 12px 15px;
  --wh-button-border-radius: 3px;
  --wh-button-height: 48px;
  --wh-field-font-size: 16px;
  --wh-field-padding: 12px 15px;
  --wh-field-border-radius: 3px;
  --wh-field-color: #979797;
  --wh-field-color--hover: inherit;
  --wh-field-color--focus: inherit;
  --wh-field-color--error: #D50000;
  --wh-field-color--disabled: #C5C5C5;
  --wh-field-border-color: #979797;
  --wh-field-border-color--hover: #D00057;
  --wh-field-border-color--focus: #D00057;
  --wh-field-border-color--error: #D50000;
  --wh-field-border-color--disabled: #F2F2F2;
  --wh-field-background-color: #fff;
  --wh-field-background-color--hover: #fff;
  --wh-field-background-color--focus: #fff;
  --wh-field-background-color--error: #fff;
  --wh-field-background-color--disabled: #ddd;
  --wh-field-border-box-shadow: none;
  --wh-field-border-box-shadow--hover: none;
  --wh-field-border-box-shadow--focus: 0 0 0 3px rgba(208,0,87,0.15);
  --wh-field-border-box-shadow--error: none;
  --wh-field-border-box-shadow--disabled: none;
  --wh-imgedit-padding: 4px;
  --wh-imgedit-margin: 2px 3px;
  --wh-imgedit-width: 150px;
  --wh-imgedit-height: 200px;
  --wh-imgedit-explain-padding: 5px;
  --wh-imgedit-uploadicon-width: 50px;
  --wh-imgedit-uploadicon-height: 50px;
  --wh-imgedit-uploadicon-margin: 0 0 20px;
  --wh-fileedit-name-margin-right: 5px;
  --wh-fileedit-margin-top: 10px;
  /*
      Conventies

      Prefix altijd:
      --wh-button-
      --wh-field-
      --wh-imgedit-
      --wh-fileedit-

      Deze prefixen centraal beheerd en besproken

      <prefix>color:
      <prefix>color--hover:
      <prefix>color--focus:
      <prefix>color--active:

      <prefix>border-color:
      <prefix>border-color--hover:
      <prefix>border-color--focus:
      <prefix>border-color--active:

      --<prefix>-<prop>[--<state>]
  */
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

::-webkit-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

::-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

:-ms-input-placeholder {
  opacity: 0.5;
  color: inherit;
}

input:-moz-placeholder {
  opacity: 0.5;
  color: inherit;
}

.wh-styledinput {
  font-size: var(--wh-field-font-size);
}
.wh-styledinput .wh-form__fieldgroup {
  margin-bottom: 15px;
}
.wh-styledinput .wh-form__fieldgroup--textarea > .wh-form__label,
.wh-styledinput .wh-form__fieldgroup--custom > .wh-form__label {
  align-self: flex-start;
  margin-top: 4px;
}
.wh-styledinput .wh-form__fieldgroup--required > .wh-form__label::after {
  display: inline-block;
  content: "*";
}
.wh-styledinput .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
  font-size: var(--wh-label-font-size);
}
.wh-styledinput .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
  font-size: var(--wh-label-font-size);
}
.wh-styledinput .wh-form__optionlabel, .wh-styledinput .wh-form__subfield > label {
  padding-right: 15px;
  font-size: var(--wh-label-font-size);
}
.wh-styledinput .wh-form__label:empty::after {
  display: none;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__label {
  flex: 0 0 var(--wh-label-width);
  max-width: var(--wh-label-width);
  min-width: var(--wh-label-width);
  font-size: var(--wh-label-font-size);
  padding-right: 5px;
}
.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: calc(100% - var(--wh-label-width));
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields {
  max-width: 100%;
}
.wh-styledinput .wh-form__fieldgroup--richtext > .wh-form__fields .wh-form__fieldline {
  display: block;
}
@media (max-width: 550px) {
  .wh-styledinput .wh-form__fieldgroup {
    flex-direction: column;
    align-items: flex-start;
  }
  .wh-styledinput .wh-form__fieldgroup > .wh-form__label {
    padding-bottom: 3px;
    padding-right: 0;
  }
  .wh-styledinput .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-styledinput .wh-form__fieldgroup > .wh-form__label, .wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
    flex: none !important;
    max-width: calc(100dvw - 40px) !important;
    min-width: 0 !important;
  }
  .wh-styledinput .wh-form__fields {
    width: 100%;
  }
  .wh-styledinput .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
    display: block;
  }
}
.wh-styledinput select + .wh-form__subfield > label,
.wh-styledinput .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-styledinput input, .wh-styledinput select:not(.wh-rtd__toolbarstyle),
.wh-styledinput textarea,
.wh-styledinput .wh-form__dateinputgroup,
.wh-styledinput .wh-form__timeinputgroup {
  border: 1px solid var(--wh-field-border-color);
  min-height: var(--wh-field-textinput-height);
  font-family: inherit;
  font-size: var(--wh-field-font-size);
  line-height: 125%;
  padding: var(--wh-field-padding);
  max-width: 100%;
  color: inherit;
  flex: 1;
  border-radius: var(--wh-field-border-radius);
  background-color: #fff;
  outline: none;
  -webkit-appearance: none;
  appearance: none;
}
.wh-styledinput input[type=time], .wh-styledinput select:not(.wh-rtd__toolbarstyle)[type=time],
.wh-styledinput textarea[type=time],
.wh-styledinput .wh-form__dateinputgroup[type=time],
.wh-styledinput .wh-form__timeinputgroup[type=time] {
  max-width: 110px;
}
.wh-styledinput input:disabled, .wh-styledinput input[data-wh-form-disabled], .wh-styledinput select:not(.wh-rtd__toolbarstyle):disabled, .wh-styledinput select:not(.wh-rtd__toolbarstyle)[data-wh-form-disabled],
.wh-styledinput textarea:disabled,
.wh-styledinput textarea[data-wh-form-disabled],
.wh-styledinput .wh-form__dateinputgroup:disabled,
.wh-styledinput .wh-form__dateinputgroup[data-wh-form-disabled],
.wh-styledinput .wh-form__timeinputgroup:disabled,
.wh-styledinput .wh-form__timeinputgroup[data-wh-form-disabled] {
  color: var(--wh-field-color--disabled);
  border-color: var(--wh-field-border-color--disabled);
  background-color: var(--wh-field-border-color--disabled);
  box-shadow: none;
}
.wh-styledinput input:not(:disabled):hover-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):hover-within,
.wh-styledinput textarea:not(:disabled):hover-within,
.wh-styledinput .wh-form__dateinputgroup:not(:disabled):hover-within,
.wh-styledinput .wh-form__timeinputgroup:not(:disabled):hover-within {
  border-color: var(--wh-field-border-color--hover);
}
.wh-styledinput input:not(:disabled):hover, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):hover,
.wh-styledinput textarea:not(:disabled):hover,
.wh-styledinput .wh-form__dateinputgroup:not(:disabled):hover,
.wh-styledinput .wh-form__timeinputgroup:not(:disabled):hover {
  border-color: var(--wh-field-border-color--hover);
}
.wh-styledinput input:not(:disabled):focus-within, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):focus-within,
.wh-styledinput textarea:not(:disabled):focus-within,
.wh-styledinput .wh-form__dateinputgroup:not(:disabled):focus-within,
.wh-styledinput .wh-form__timeinputgroup:not(:disabled):focus-within {
  border-color: var(--wh-field-border-color--focus);
  box-shadow: var(--wh-field-border-box-shadow--focus);
}
.wh-styledinput input:not(:disabled):focus, .wh-styledinput select:not(.wh-rtd__toolbarstyle):not(:disabled):focus,
.wh-styledinput textarea:not(:disabled):focus,
.wh-styledinput .wh-form__dateinputgroup:not(:disabled):focus,
.wh-styledinput .wh-form__timeinputgroup:not(:disabled):focus {
  border-color: var(--wh-field-border-color--focus);
  box-shadow: var(--wh-field-border-box-shadow--focus);
}
.wh-styledinput textarea {
  min-height: var(--wh-field-textarea-height);
}
.wh-styledinput .wh-form__fieldgroup--error input, .wh-styledinput .wh-form__fieldgroup--error select,
.wh-styledinput .wh-form__fieldgroup--error textarea,
.wh-styledinput .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-styledinput .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  border-color: var(--wh-field-border-color--error);
}
.wh-styledinput .wh-form__error {
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: var(--wh-field-color--error);
  padding: 8px 0 0;
  font-size: 15px;
}
.wh-styledinput .wh-form__fieldgroup--error .wh-form__error {
  display: block;
}