
/**********************************************************
Layout page so that the footer is always pushed to
either the bottom of the viewport or the bottom of the page
**********************************************************/

html
{
  overflow-y: scroll;
  padding: 0;
  margin: 0;
}

/* take remaining space to push footer down the buttom.
   (otherwise we have to test for each type of (possible custom) pages to see if the footer is pushed down correctly)
   
   Although margin-top: auto; would partly work on the footer,
   the margins would collapse and there's no way to specify a minimum margin-top.  
*/
.site__footerspacer
{
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

/*
Method to push footer to bottom on modern browsers.
(this doesn't work in IE10 but in IE11 and modern browsers)

body
{
  display: -ms-flexbox; / * IE10 * /
  display: -webkit-flex; / * needed for iOS < 9.2 and SF < 9 * /
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  padding: 0;
  margin: 0;
  min-height: 100vh;
}
*/


/* Method to push the footer to the bottom in
   modern browsers + IE10 and IE11
*/
html
{
  height: 100%;
}
body
{
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;
  height: 100%; /* We must use height instead of min-height for IE10 */
  padding: 0;
  margin: 0;
}
.body__content
{
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;

  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;

  width: 100%; /* adjustment */
}

/* We must use height on <body> instead of min-height for IE10,
   however this will activate flex children shrinking to fit in this 100%.
   To counteract the shrinking we will set flex-shrink to 0, forcing <body> to grow to fit (and make the height act as min-height).
   (now content will act like normal <div>'s)
*/
.body__content > *
{
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
