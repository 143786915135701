/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.richdoc + .activitiesoverview {
  margin-top: 40px;
}

/* Control bar *****************************************************************/
.activitiesoverview__controlbar + * {
  margin-top: 20px;
}

.activitiesoverview__controlbar {
  /* [IEFIX] */
}
.activitiesoverview__controlbar select {
  padding-left: 10px;
  height: 41px;
  border: 1px solid #CCCCCC;
  border-radius: 0;
  color: #222222;
  font: inherit;
  line-height: 39px; /* 41 - 2px border */
  /* The SVG image needs preserveAspectRatio="xMaxYMid" to get positioned at the right in IE10/11 (and might also need the viewBox) */
  background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNYXhZTWlkIiB2aWV3Qm94PSIwIDAgNC45NSAxMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==) no-repeat 95% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 30px;
}
.activitiesoverview__controlbar select:focus, .activitiesoverview__controlbar select:hover {
  border-color: #D00057;
  outline: 0;
}
.activitiesoverview__controlbar select::-ms-expand {
  display: none;
}
@-moz-document url-prefix() {
  .activitiesoverview__controlbar select, .activitiesoverview__controlbar select:-moz-focusring, .activitiesoverview__controlbar select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    /*border:0;*/
  }
}

.contentgrid__resultscount {
  color: #D00057;
}

.contentgrid__category,
.contentgrid__resultscount {
  align-self: center;
}

.contentgrid__resultscount {
  margin-right: 20px;
}

.contentgrid__viewtype {
  margin-left: auto;
}

.contentgrid__viewtype {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
  height: 41px;
  -ms-flexbox-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.contentgrid__viewtype .comp-togglebutton-visual {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 41px;
  height: 41px;
}

.contentgrid__viewtype .comp-togglebutton-visual svg {
  height: 14px;
  margin: auto;
}

/* Activities grid *****************************************************************/
.contentgrid--grid {
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;
  -webkit-flex-flow: row wrap; /* flex-wrap: wrap */
  flex-flow: row wrap; /* flex-wrap: wrap */
}

@media (min-width: 600px) {
  .contentgrid .activity__meta {
    display: -ms-flexbox; /* IE10 */
    display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .contentgrid--grid .activity__meta {
    position: relative;
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}
.contentgrid--grid .activity__meta {
  padding: 7px 14px 7px 14px;
}

@media (min-width: 500px) {
  .contentgrid--list .activity__meta {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
/* IMPORTANT NOTE:
   We use width instead of flex-basis for setting the width because:
   - IE10/11: don't use flex-basis because it incorrectly assumes to always have box-sizing: content-box;
   - IE10: calc() not supported, even in longhand flex-basis declarations
*/
@media (max-width: 599px) {
  .contentgrid--grid.contentgrid--cols2 .contentgrid__item,
  .contentgrid--grid.contentgrid--cols3 .contentgrid__item,
  .contentgrid--grid.contentgrid--cols4 .contentgrid__item {
    width: 100%;
  }
  .contentgrid--grid .contentgrid__item + .contentgrid__item {
    margin-top: 20px;
  }
}
@media (min-width: 600px) and (max-width: 940px) {
  .contentgrid--grid {
    width: calc(100% + 20px);
    margin-bottom: -20px;
  }
  .contentgrid--grid.contentgrid--cols2 .contentgrid__item,
  .contentgrid--grid.contentgrid--cols3 .contentgrid__item,
  .contentgrid--grid.contentgrid--cols4 .contentgrid__item {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
}
@media (min-width: 941px) {
  .contentgrid--grid {
    width: calc(100% + 20px);
    margin-bottom: -20px;
  }
  .contentgrid--grid.contentgrid--cols2 .contentgrid__item {
    width: calc(50% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .contentgrid--grid.contentgrid--cols3 .contentgrid__item {
    width: calc(33.33% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .contentgrid--grid.contentgrid--cols3 .contentgrid__item--wide {
    width: calc(66.66% - 20px);
  }
  /*
  .contentgrid.contentgrid--cols4 .contentgrid__item
  {
    width: calc(25% - 20px);
    margin-right: 20px;
    margin-bottom: 20px;
  }
  */
}
.contentgrid__item--fullwidth {
  width: 100%;
}

.contentgrid__item--wide {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.contentgrid__item--hide {
  display: none !important;
}

/* negative margins in a .wh-rtd-embeddedobject (in the RTE) causes scrollbars) */
/*
.wh-rtd-editor .contentgrid .widgetsgrid
{
  margin-bottom: 0;
}
*/
/*
This also works nicely for layout.
+ (GOOD) we don't have margins which might stick out of the page causing a scrollbar to appear

.contentgrid
{
  justify-content: space-between;
}

.contentgrid.contentgrid--cols3 .contentgrid__item
{
  width: calc(33.33% - 20px * 2 / 3);
}

.contentgrid__items:last-child
{
  margin-right: auto;
}
*/
/*
.contentgrid::after
{
  content: "";
  display: inline-block; / * [IEFIX] * /
  -webkit-flex-grow: 1;
  flex-grow: 1;
  background-color: #F00;
}
*/
.contentgrid--grid .contentgrid__item:not(.contentgrid__item--noborder) {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  border: 1px solid #D2D2D2;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.06);
}

.contentgrid--list .contentgrid__item {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding: 10px 10px 10px 0;
  border-top: 1px solid #979797;
}

.contentgrid--list .activity__image {
  width: 160px;
  margin-right: 15px;
}

@media (max-width: 499px) {
  .contentgrid--list .activity__image {
    display: none;
  }
}
.contentgrid--list .activity__readmore {
  display: none;
  /* margin-top: auto; */
}

.contentgrid--grid .activity__readmore {
  font-weight: 700;
  text-decoration: none;
}

.contentgrid--grid .activity__readmore::after {
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  content: "\f054"; /* fa-chevron-right */
  margin-left: 10px;
  font: normal normal normal 100%/1 FontAwesome;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.activity__image {
  display: block;
  text-decoration: none;
  position: relative;
  background-size: cover;
  overflow: hidden; /* never let the labels escape our box */
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.activity__image::before {
  display: block;
  content: "";
  padding-top: 66.66%; /* 3 x 2 aspect ratio */
}

.activity__image--placeholder {
  background-color: #F0F0F0;
}

.activity__label {
  display: block;
  text-decoration: none;
}

.contentgrid--grid .activity__label {
  position: absolute;
  transform: translate(0, -100%);
  left: 0;
  top: 0;
}

.contentgrid--grid .activity__label__lines {
  display: table;
  padding: 3px 15px;
  font: 700 18px/22px "Raleway", sans-serif;
  color: #FFFFFF;
  background-color: #D00057;
}

.contentgrid--grid .activity__label__lines + .activity__label__lines {
  margin-top: 1px;
}

.activity__description {
  font: 14px/20px Arial;
  color: #4A4A4A;
  /* eat space to push the readmore link to the bottom */
  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}

.activity__description + .activity__readmore {
  margin-top: 7px;
}

.activity__label + .activity__readmore {
  margin-top: auto;
}

.contentgrid--list .activity__label__lines {
  color: #D00057;
}