/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.searchcontrol {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.searchcontrol input {
  -webkit-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  height: 41px;
  border: 0;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid #cccccc;
  border-radius: 0;
  background-color: #fff;
  color: #222222;
  font: inherit;
  border-color: #D00057;
  outline: 0;
}
.searchcontrol button {
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
}
.searchcontrol button > .wh-form__button {
  background-color: #D00057;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  height: 41px;
  line-height: 41px;
  padding: 0 20px;
  border: 0;
  cursor: pointer;
  font: inherit;
}

.searchresults {
  margin-top: 40px;
}

.page--search .searchcontrol svg {
  height: 20px;
  margin-left: 15px;
  vertical-align: middle;
  fill: #FFFFFF;
}

.searchresult + .searchresult {
  margin-top: 30px;
}

a.searchresult__title {
  text-decoration: none;
}