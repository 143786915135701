/* based on the menu-listtree DOM */
/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.site__submenu .listtreemenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.site__submenu .listtreemenu a {
  text-decoration: none;
  color: #6C6C6C;
}

.site__submenu .listtreemenu a:hover,
.site__submenu .listtreemenu a:active,
.site__submenu .listtreemenu a.selected {
  color: #D00057;
}

.site__submenu .listtreemenu--depth1 > li > a {
  display: block;
  padding-top: 9px;
  padding-bottom: 7px;
  border-top: 1px solid #1E8D4C;
  font: 300 15px "Raleway", sans-serif;
}

.site__submenu .listtreemenu--depth2 {
  padding-bottom: 7px; /* to increase the whitespace of the last depth2 item to the size of padding-bottom of a depth1 item */
}

.site__submenu .listtreemenu--depth2 > li > a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  font: 300 14px Arial;
}

.site__submenu .listtreemenu--depth3 > li > a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  font-size: 13.5px;
}

.site__submenu .listtreemenu--depth2 > li > a::before,
.site__submenu .listtreemenu--depth3 > li > a::before {
  display: inline-block;
  content: "-";
  margin-right: 5px;
  -ms-flex-negative: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}