/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.page__crumbpath {
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: flex;
  /*height: 36px;*/
  /*
    -ms-flex-negative: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  */
  -webkit-align-items: center;
  align-items: center;
  /*white-space: nowrap;*/
}

/* hide crumbpath on mobile */
@media (max-width: 799px) {
  .page__crumbpath {
    display: none;
  }
  .page__crumbpath + .content {
    margin-top: 20px;
  }
}
.page__crumbpath > *:first-child {
  margin-left: -7px;
}

.page__crumbpath a {
  text-decoration: none;
}

/* spacing and increase of hitarea */
.page__crumbpath a,
.page__crumbpath span {
  padding-left: 7px;
  padding-right: 7px;
}