/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
.siteheader {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding-top: 10px;
}

.siteheader__logoanchor {
  -webkit-align-self: flex-start;
  align-self: flex-start;
  margin-bottom: -10px;
  z-index: 1;
}

.siteheader__logo {
  display: block;
  height: 94px;
}

.siteheader__menu {
  margin-left: auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.siteheader__menu a {
  display: inline-block; /* [IEFIX] */
  text-decoration: none;
}

.siteheader__menu a + a {
  margin-left: 20px;
}

.siteheader__search {
  margin-left: 55px;
}

.siteheader__image {
  margin-left: 0;
  margin-right: 0;
  background-size: cover;
  height: 33vw;
  min-width: 79px;
  max-height: 307px;
}

/* DESKTOP */
@media (min-width: 800px) {
  .siteheader {
    max-width: 1248px;
  }
  .siteheader__image {
    /*height: 307px;*/
    max-width: 1248px;
  }
}
.searchcontrol__submit {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
}

.searchcontrol__submiticon {
  margin-left: 15px;
  width: 23px;
  -webkit-align-self: center;
  align-self: center;
  margin-top: -2px;
}