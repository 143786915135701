/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
/* content size must be max(content-height, (body)height, 100vh)
   Force a second min-height using a before
*/
/*
  #slidemenu-container::before
, #slidemenu::before
{
  content: "";
  display: block;
  height: 100%;
  float: left;
  width: 0;
}
*/
#slidemenu-container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  height: 100vh;
}
#slidemenu-container .button--icon {
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
}

html.ienopointerevents #slidemenu-container {
  z-index: -1; /* [IEFIX] IE10 doesn't have pointer-events */
}

html.ienopointerevents.site--modality--mobilemenu #slidemenu-container {
  z-index: auto;
}

/*
Menu (which can be toggled) for mobile & tablet (portrait orientation)
*/
#slidemenu {
  position: fixed;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background-color: #950340;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  /* Don't take space when inactive
     If we use display: none; we cannot use transitions.
     (or we need more js code and might get a flicker on mobile devices)
  height: 0;
  overflow: hidden;
  */
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.slidemenu--fromtheleft {
  left: 0;
  -webkit-transform: translateX(calc(-100% - 5px));
  -ms-transform: translateX(-100%);
  transform: translateX(calc(-100% - 5px));
}

.slidemenu--fromtheright {
  right: 0;
  -webkit-transform: translateX(calc(100% + 5px));
  -ms-transform: translateX(100%);
  transform: translateX(calc(100% + 5px));
}

/* IE10 fallback */
html.no-pointerevents #site-search-panel {
  z-index: -1;
}

#slidemenu:focus {
  outline: none;
}

#slidemenu.active {
  overflow: auto;
  pointer-events: auto;
  z-index: 1;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}