/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
/*

NOTES:

- by targetting the element AFTER the element for which we meant the margin (as margin-bottom)
  We can more easily override the margin using the sibling selector.
  (for example .richdoc h1 + * { margin-top: 11px; })

*/
html {
  color: #000000;
}

a {
  color: #D00057;
}

a:hover {
  color: #950340;
}

a,
.richdoc h1, .wh-form__richtext h1,
.richdoc h2, .wh-form__richtext h2,
.richdoc h3, .wh-form__richtext h3,
.wh-webpacksurvey__title,
.wh-webpacksurvey__pagetitle,
.wh-webpacksurvey__grouptitle,
.searchresult__title {
  color: #D00057;
}

.richdoc > * {
  margin: 0;
}

.wh-rtd__tablecell > *:first-child {
  margin-top: 0;
}

.content__contentcolumn > main > .richdoc--toplevel > h1 {
  margin-top: -3px;
}

.siteheader__menu,
.siteheader__search {
  font: 500 18px "Raleway", sans-serif;
  color: #6C6C6C;
}

.siteheader__search {
  color: #A3A3A3;
}

.siteheader__menu a {
  color: inherit;
}

.siteheader__menu a:hover {
  color: #D00057;
}

/* .siteheader__menu a.searchcontrol__submit .icon { fill: $themecolor; } */
.slidemenu__sitename {
  font: 700 18px "Raleway", sans-serif;
}

.page__crumbpath span,
.page__crumbpath a {
  color: #6C6C6C;
}

.page__crumbpath a:hover {
  color: #D00057;
}

.page__crumbpath {
  font: 300 12px "Raleway", sans-serif;
}

/* MOBILE */
@media (max-width: 799px) {
  html {
    font: 15px/21px Arial, Sans-Serif;
  }
  .richdoc > * + *,
  .wh-form__richtext > * + * {
    margin-top: 11px;
  }
  .richdoc h1, .wh-form__richtext h1,
  .fs_h1 {
    font: 700 22px/28px "Raleway", sans-serif;
    margin-top: 32px;
    margin-bottom: -3px;
  }
  .richdoc h2, .wh-form__richtext h2,
  .fs_h2,
  .wh-webpacksurvey__title,
  .searchresult__title {
    font: 700 18px/21px "Raleway", sans-serif;
    margin-top: 32px;
  }
  .richdoc h2, .wh-form__richtext h2,
  .fs_h2 {
    margin-bottom: -6px;
  }
  .richdoc h3, .wh-form__richtext h3,
  .fs_h3,
  .wh-webpacksurvey__pagetitle {
    font: 700 16px/21px "Raleway", sans-serif;
    margin-top: 32px;
  }
  .richdoc h3, .wh-form__richtext h3,
  .fs_h3 {
    margin-bottom: -3px;
  }
  .wh-webpacksurvey__title {
    margin-top: 0;
  }
  .wh-webpacksurvey__grouptitle {
    font: 500 16px/21px "Raleway", sans-serif;
    margin-top: 32px;
    margin-bottom: -3px;
  }
  .contentgrid--list .activity__label {
    font: 700 15px/21px "Raleway", sans-serif;
  }
  .richdoc p.intro, .wh-form__richtext p.intro {
    font: 500 15px/21px "Raleway", sans-serif;
  }
  .contentgrid__resultscount {
    font: 500 15px/21px "Raleway", sans-serif;
  }
}
/* TABLET (PORTRAIT) and DESKTOP share the same font settings */
@media (min-width: 800px) {
  html {
    font: 15px/21px Arial, Sans-Serif;
  }
  .richdoc > * + *,
  .wh-form__richtext > * + * {
    margin-top: 11px;
  }
  .richdoc h1, .wh-form__richtext h1,
  .fs_h1 {
    font: 700 33px/36px "Raleway", sans-serif;
    margin-top: 32px;
    margin-bottom: -3px;
  }
  .richdoc h2, .wh-form__richtext h2,
  .fs_h2,
  .wh-webpacksurvey__title,
  .searchresult__title {
    font: 700 21px/21px "Raleway", sans-serif;
    margin-top: 32px;
  }
  .richdoc h2, .wh-form__richtext h2,
  .fs_h2 {
    margin-bottom: -6px;
  }
  .wh-webpacksurvey__title {
    margin-top: 0;
  }
  .richdoc h3, .wh-form__richtext h3,
  .fs_h3,
  .wh-webpacksurvey__pagetitle {
    font: 700 18px/21px "Raleway", sans-serif;
    margin-top: 32px;
  }
  .richdoc h3, .wh-form__richtext h3,
  .fs_h3 {
    margin-bottom: -3px;
  }
  .wh-webpacksurvey__grouptitle {
    font: 500 16px/21px "Raleway", sans-serif;
    margin-top: 32px;
    margin-bottom: -3px;
  }
  .contentgrid--list .activity__label {
    font: 700 18px/21px "Raleway", sans-serif;
  }
  .richdoc p.intro, .wh-form__richtext p.intro {
    font: 500 16px/24px "Raleway", sans-serif;
  }
  .contentgrid__resultscount {
    font: 500 16px/24px "Raleway", sans-serif;
  }
}