/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
/*

.content--centered - content centered, can grow up to the 941px of the desktop design
.content--alignleft-with-centeredcontent

calc -> any modern browser
css vars ->
flex ->

*/
* {
  -moz-box-sizing: border-box; /* FF<=28 */
  -webkit-box-sizing: border-box; /* SF<=5, iOS<=4.3, Android<=3 */
  box-sizing: border-box;
  -ms-flex-negative: 1; /* [IEFIX] IE10 has set to 0 by default, causing content to not wrap and run out of their containers */
}

body {
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

body {
  /* be a positioning container so #slidemenu-container
     can stretch to the full page size.
     (and it can act as clipping container to prevent the menu from
     stretching the <body> when it's animated outside the page)
  */
  position: relative;
}

a img {
  border: 0; /* [IEFIX] IE10 adds a border to any image within an anchor */
}

main {
  display: block; /* [IEFIX] IE10/11 don't treat it as a block, making padding's disappear */
}

[data-popup],
.wh-share {
  cursor: pointer;
}

.site__footerspacer {
  min-height: 40px;
}

/* MOBILE */
@media (max-width: 799px) {
  /* in mobile and tablet mode we use all horizontal space, only leaving some small whitespace at the sides */
  .content--centered,
  .widecontent--centered,
  .widecontent--centered--atcontentleft {
    margin-left: 15px;
    margin-right: 15px;
  }
  .page__crumbpath,
  .content {
    margin-top: 25px;
  }
}
/* TABLET (PORTRAIT) */
@media (max-width: 799px) {
  /* in mobile and tablet mode we use all horizontal space, only leaving some small whitespace at the sides */
  .content--centered,
  .widecontent--centered,
  .widecontent--centered--atcontentleft {
    margin-left: 20px;
    margin-right: 20px;
  }
  .page__crumbpath,
  .content {
    margin-top: 25px;
  }
}
/* DESKTOP */
@media (min-width: 800px) {
  .content--centered,
  .widecontent--centered,
  .widecontent--centered--atcontentleft {
    max-width: 941px;
    margin-left: 20px;
    margin-right: 20px;
    /*
    OR USE:
    width: calc(100% - 40px;)
    margin-left: auto;
    margin-right: auto;

    OR USE:
    width: calc(100% - 40px;)
    align-self: center;
    */
  }
  .widecontent--centered--touchedge {
    max-width: 941px;
  }
  .page__crumbpath,
  .content {
    margin-top: 25px;
  }
}
/* DESKTOP + whitespace around the content */
@media (min-width: 961px) {
  .content--centered {
    width: 100%;
    max-width: 941px;
    margin-left: auto;
    margin-right: auto;
  }
  .widecontent--centered {
    width: 100%;
    max-width: 1248px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  .widecontent--centered--touchedge {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    -webkit-align-self: center;
    align-self: center;
  }
  /* content which should align with normal centeredcontent, but stick out to the right */
  .widecontent--centered--atcontentleft {
    margin-left: calc((100% - 941px) / 2);
    margin-right: 20px;
  }
}
/* if we reach 1248 + 2*20px we have enough whitespace at the sides
   to not need padding within widecontent--centered anymore */
@media (min-width: 1268px) {
  .widecontent--centered {
    padding-left: 0;
    padding-right: 0;
  }
  /* content which should align with normal centeredcontent, but stick out to the right */
  .widecontent--centered--atcontentleft {
    margin-left: calc((100% - 941px) / 2);
    margin-right: calc((100% - 1248px) / 2);
  }
}
@media (max-width: 899px) {
  .content__menucolumn {
    display: none;
  }
}
/* two column page layout (not to be confused with the amount of columns in a widgetgrid) */
@media (min-width: 900px) {
  /*
      .content--wide
    , .content--twocolumn
    {
      -ms-flex-negative: 0;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;
    }
  */
  .content--twocolumn {
    /* if we used flex for vertical (mobile) mode the max-width: 100% on images would not work */
    display: -ms-flexbox; /* IE10 */
    display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
    display: flex;
    width: 100%;
  }
  .content__menucolumn {
    width: 215px;
    -ms-flex-shrink: 0;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    margin-right: 25px;
    /* reset the padding from content--padh */
    padding-left: 0;
    padding-right: 0;
  }
  .content__contentcolumn {
    /* consume any available space */
    width: 0; /* [IEFIX] makes IE shrink down to what room is available instead of what it would need to not have to wrap text */
    -ms-flex-positive: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
  }
}