/* radio / checkbox styling */
.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-styledinput input[type=radio],
.wh-styledinput input[type=checkbox] {
  display: block;
  position: absolute;
  left: -9999px;
  width: 0px;
  height: 0px;
}
.wh-styledinput input[type=radio] + label,
.wh-styledinput input[type=checkbox] + label {
  vertical-align: top;
  cursor: pointer;
  position: relative;
  top: 6px;
  width: var(--wh-field-radiocheckbox-size);
  max-width: var(--wh-field-radiocheckbox-size);
  flex: 0 0 var(--wh-field-radiocheckbox-size);
  height: var(--wh-field-radiocheckbox-size);
  border: 1px solid var(--wh-field-border-color);
  border-radius: var(--wh-field-border-radius);
  background-color: var(--wh-field-background-color);
}
.wh-styledinput input[type=radio] + label::after,
.wh-styledinput input[type=checkbox] + label::after {
  font-family: "FontAwesome";
  content: "";
  font-weight: 700;
  position: absolute;
  top: 0;
  left: 0;
  color: var(--wh-field-color);
  font-size: 16px;
  display: block;
  padding: 3px 0 0 3px;
  width: 100%;
  height: 100%;
  line-height: 16px;
  transition: all 0.3s;
}
.wh-styledinput input[type=radio]:hover + label,
.wh-styledinput input[type=checkbox]:hover + label {
  border-color: var(--wh-field-border-color--hover);
  box-shadow: var(--wh-field-border-box-shadow--hover);
}
.wh-styledinput input[type=radio]:focus + label,
.wh-styledinput input[type=checkbox]:focus + label {
  border-color: var(--wh-field-border-color--focus);
  box-shadow: var(--wh-field-border-box-shadow--focus);
}
.wh-styledinput input[type=radio][disabled] + label,
.wh-styledinput input[type=checkbox][disabled] + label {
  border-color: var(--wh-field-border-color--disabled);
  cursor: default;
}
.wh-styledinput input[type=radio][disabled] + label + *,
.wh-styledinput input[type=checkbox][disabled] + label + * {
  color: var(--field-color);
}
.wh-styledinput .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-styledinput .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-styledinput .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-styledinput .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-styledinput .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields {
  margin-left: -20px;
}
.wh-styledinput .wh-form__fieldgroup--horizontal > .wh-form__fields > .wh-form__fieldline {
  padding-left: 20px;
}
.wh-styledinput .wh-form__fieldgroup--horizontal .wh-form__fieldline--nowrap {
  margin-top: 10px;
}
.wh-styledinput input[type=radio] + label {
  border-radius: 50%;
}
.wh-styledinput input[type=radio] + label::after {
  border: 4px solid #fff;
  border-radius: 50%;
}
.wh-styledinput input[type=radio]:checked + label::after {
  background-color: var(--wh-field-border-color--hover);
}
.wh-styledinput input[type=radio]:checked + label {
  border-color: var(--wh-field-border-color--hover);
}
.wh-styledinput input[type=radio][disabled] + label::after {
  background-color: var(--field-color--disabled);
  border-color: var(--field-background-color--disabled);
}
.wh-styledinput input[type=radio][disabled]:checked + label::after {
  background-color: var(--wh-field-color--disabled);
}
.wh-styledinput input[type=checkbox]:checked + label::after {
  content: "\f00c";
  color: var(--wh-field-border-color--hover);
}
.wh-styledinput input[type=checkbox]:checked + label {
  border-color: var(--wh-field-border-color--hover);
}
.wh-styledinput input[type=checkbox][disabled] + label::after {
  content: "";
}
.wh-styledinput input[type=checkbox][disabled] + label {
  background-color: var(--wh-field-border-color--disabled);
}
.wh-styledinput input[type=checkbox][disabled]:checked + label::after {
  content: "\f00c";
  color: var(--wh-field-color--disabled);
}
.wh-styledinput .wh-form__fieldgroup--error input[type=checkbox]:not(:checked) + label,
.wh-styledinput .wh-form__fieldgroup--error input[type=radio]:not(:checked) + label {
  border-color: var(--wh-field-border-color--error);
}