.wh-video
{
/*  display: flex;*/
  position: relative;
}

.wh-video--aspect_4_3::before
{
  display: block;
  content: "";
  padding-top: 75%;
}

.wh-video--aspect_16_9::before
{
  display: block;
  content: "";
  padding-top: 56.25%;
}

.wh-video iframe
{
  height: auto !important;
  flex-grow: 1;
}

/* This method works in all browsers.
   (Chrome, Safari, Firefox and IE all have some differences, but this works for all)
*/

.wh-video__wrapper
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -ms-flex-positive: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;

  -webkit-flex-direction: column;
  flex-direction: column;
}


/*
.wh-video
{
  position:relative;
  width:100%;
}
.wh-video:after
{
  display:block;
  content: '';
}
.wh-video.wh-video--aspect_16_9:after
{
  padding-top:56.25%;
}
.wh-video.wh-video--aspect_4_3:after
{
  padding-top:56.25%;
}
.wh-video > iframe
{
  position:absolute;
  width:100%;
  height:100%;
}
*/