
/* dialog */
html.dialog--active
{
  overflow: hidden;
}

.dialog__modal
{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 100;
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: 50% 50%;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.2s;
  margin: 0;
  padding: 0;
}
.dialog__modal.aftershow
{
  opacity: 1;
}
.dialog__modal:before
{
  display: inline-block;
  content: '';
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.dialog__body
{
  text-align: left;
  display: inline-block;
  white-space: normal;
  background-color: #fff;
  padding: 15px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  min-width: 200px;
  max-width: calc(100% - 40px);
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  transform: scale(0,0);
  transition: transform 0.2s;
}
.dialog__modal.aftershow .dialog__body
{
  transform: scale(1,1);
}
@media (max-width: 600px)
{
  .dialog__body
  {
    max-width: calc(100% - 20px);
  }
}
@media (max-width: 400px)
{
  .dialog__body
  {
    max-width: 100%;
    min-width: calc(100% - 30px);
  }
}

.dialog__modal .close
{
  font-size: 29px;
  line-height: 29px;
  padding-top: 10px;
  display: inline-block;
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  transition: transform 0.3s;
}
.dialog__modal .close:hover > .fa
{
  transform: scale(1.2, 1.2);
}
.dialog__modal .dialog__body .close
{
  right: 10px;
  top: 10px;
}
.dialog__modal .dialog__body .close > .fa
{
  font-size: 36px;
  margin-left: -4px;
}
.dialog__modal .dialog__body .close > .fa + .fa
{
  margin-left: 4px;
}

.dialog__body > .title
{
  font-size: 18px;
  text-transform: uppercase;
  margin: 0 0 20px;
  padding-right: 35px;/* space for close btn */
}
.dialog__body > .title + .dialog__content
{
  padding-top: 15px;
}
.dialog__body > .title + .dialog__content
{
  padding-top: 0;
}
.dialog__content p
{
  margin-top: 15px;
}
.dialog__body h3
{
  margin-top: 0;
  margin-bottom: 5px;
}

html.dialog--active body
{
  overflow: hidden;
}

/* photoalbum overlay */
.dialog__modal.photoalbum
{
  background-color: #3B3B3B;
}
.dialog__modal.photoalbum .dialog__body
{
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
  width: 100%;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
  background-color: transparent;
  color: #fff;
}
.dialog__modal.photoalbum .dialog__body .close
{
  color: #fff;
  z-index: 10;
}
.dialog__modal.photoalbum .dialog__content
{
  height: 100%;
}
.dialog__modal.photoalbum .dialog__body > .title
{
  font: 300 24px/28px "Raleway", Sans-Serif, Arial;
  padding: 25px;
}

.photoalbum-overlay
{
  height: 100%;
  width: 100%;
}
.photoalbum-overlay .slides
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 115px;
  width: 100%;
}
.photoalbum-overlay .image
{
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: transform 500ms cubic-bezier(0.5, 0, 0, 1), width 200ms, height 200ms;
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.3);
  background-color: #f1f1f1;
}
.photoalbum-overlay .image > .title
{
  padding: 0;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 10px;
  text-align: center;
  font: 300 15px/20px "Raleway", Sans-Serif, Arial;
}

  .photoalbum-overlay .previousimage
, .photoalbum-overlay .nextimage
{
  font-size: 33px;
  color: #fff;
  position: absolute;
  top: 50%;
  margin-top: -16px;
  opacity: 1;
  width: 55px;
  height: 70px;
  padding-top: 15px;
  cursor: pointer;
}
.photoalbum-overlay .previousimage
{
  transition: opacity 0.2s, left 0.2s;
  left: 0;
  padding-left: 20px;
}
.photoalbum-overlay .previousimage:hover
{
  left: -3px;
}
.photoalbum-overlay .nextimage
{
  transition: opacity 0.2s, right 0.2s;
  right: 0;
  padding-left: 20px;
}
.photoalbum-overlay .nextimage:hover
{
  right: -3px;
}

.photoalbum-overlay .previousimage.disabled
{
  left: 0;
  cursor: default;
  opacity: 0.3;
}
.photoalbum-overlay .nextimage.disabled
{
  right: 0;
  cursor: default;
  opacity: 0.3;
}


.photoalbum-overlay .image > img
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.8s;
}
.photoalbum-overlay .thumbnails
{
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 115px;
  background-color: #2B2B2B;
  overflow: hidden;
  transition: height 700ms 190ms;
}
.photoalbum-overlay .thumbnails > ul
{
  list-style-type: none;
  margin: 0;
  padding: 15px 0 15px 15px;
  white-space: nowrap;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}
.photoalbum-overlay .thumbnails > ul > li
{
  display: inline-block;
  height: 100%;
  position: relative;
  margin-right: 15px;
  cursor: pointer;
  overflow: hidden;
  background-color: #f1f1f1;
}
.photoalbum-overlay .thumbnails > ul > li.active
{
  border: 2px solid #3BA1FA;
}
.photoalbum-overlay .thumbnails > ul > li > img
{
  position: absolute;
  top: 0;
  height: 85px;
  width: auto;
  left: -9999px;
  right: -9999px;
  margin: 0 auto;
  pointer-events: none;
}

