/*******************************************************************************
 * CONFIGURATION
 */
/*
LAYOUTS
- Normal (twocolumn menu/content)
- Wide (voor de activiteiten pagina)

1024 -> Site heeft (1024 - 940)/2 = 84/2 = 42px linkes en rechts aan witruimte


MARGINS

D  / T   / M
20 /     /   - Margin tussen menu en pageheaderimage
40 / 24 / 18 - Margin tussen header en pagecontent (als geen breadcrumb path)



FONT-SIZES

            - H1
            - H2
            - H3
            - H4
            - P


DESKTOP

content 941
pageheader max width 1248


Breakpoints
*/
/* if possible this is the used width. The contentarea never grows larger, but is allowed to shrink a little. */
/* Breakpoints to remove whitespace because there's enough whitespace surrounding the content elements */
/* the menu will jump to a lower devicelayout quicker,
   because it's menu cannot just wrap
*/
/* a little larger than mobile */
/* control the available size */
/* use desktop view, even though we might have to shrink the content a little */
/*
ORIGIN: < WVHG < ?SU?

V2 ?
- Incompatible with WVHG version

Used for toggle button bar in MST_Reelex activities page

*/
/*
  .wh-styledinput input[type='checkbox']
, .wh-styledinput input[type='radio']
*/
.comp-togglebuttonbar input[type=checkbox],
.comp-togglebuttonbar input[type=radio] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
}

.comp-togglebuttonbar {
  background-color: #F1F1F1;
}

.comp-togglebutton {
  /* [IEFIX] We need to use <label> to have a natively stylable, interactable replacement for the checkbox.
             <label> however is inline and IE10 doesn't see inline elements as normal flex child */
  display: inline-block;
  /*
    background-color: #FFFFFF;
    color: #1c9f2c;
  */
}

input:checked + .comp-togglebutton-visual {
  background-color: #D00057;
  color: #FFFFFF;
}

input:checked + .comp-togglebutton-visual svg {
  fill: #FFFFFF;
}

.comp-togglebuttonbar {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  border-radius: 0;
  overflow: hidden;
}

.comp-togglebutton {
  -webkit-flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-grow: 1;
  flex-shrink: 1;
  /*
    line-height: 29px;
    color: #000000;
    text-align: center;
    border: 1px solid #CCCCCC;
  */
  cursor: pointer;
}

input:checked + .comp-togglebutton-visual + input:checked + .comp-togglebutton-visual {
  /*
    border-left: 1px solid rgba(255, 255, 255, 0.25);
  */
}