/* css styled pulldown */
.wh-form__pulldown-styled {
  position: relative;
  flex: 1;
  min-width: 100px;
}
.wh-form__pulldown-styled select {
  padding-right: 35px;
  width: 100%;
}
.wh-form__pulldown-styled select:disabled {
  opacity: 0.4;
}
.wh-form__pulldown-styled select:disabled + .arrow {
  opacity: 0.4;
}
.wh-form__pulldown-styled select::-ms-expand {
  display: none; /* hide select arrow in IE */
}
.wh-form__pulldown-styled .arrow {
  font-size: 22px;
  position: absolute;
  right: 15px;
  top: 50%;
  color: var(--wh-field-color);
  margin-top: -10px;
  pointer-events: none;
}
.wh-form__pulldown-styled select[disabled] + .arrow {
  opacity: 0.2;
}
.wh-form__pulldown-styled::after {
  display: block;
  content: "";
  width: 25px;
  position: absolute;
  z-index: 2; /* On top of select */
  right: 28px;
  top: 3px;
  bottom: 3px;
  pointer-events: none;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 ); /* IE6-9 */
}
.wh-form__pulldown-styled--disabled::after {
  display: none;
}