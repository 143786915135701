/*

GENERIC
   In rtd-shared.css we cannot
   - use css variables for this (because of IE and Edge)
   - use SASS (variables/mixins) (because of the preview in the richeditor which doesn't support SASS)


This stylesheet can NOT be a SASS stylesheet.
It must be useable within the richtext editor (which does not support SASS for includes stylesheets)
*/

/*
@import url(//fonts.googleapis.com/css?family=Dosis:600,400);

@font-face
{
  font-family: "Escolar_N";
  src: url("../../web/fonts/escolar2negra.woff");
}

@font-face
{
  font-family: "Blokletters Viltstift";
  src: url("../../web/fonts/blokletters-viltstift.ttf");
}

@font-face
{
  font-weight: bold;
  font-family: "VAGRoundedStd";
  src: url("../../web/fonts/vagroundedstd-bold-webfont.woff") format("woff");
}
*/


.widgets--spacing .widget + .widget
{
  margin-top: 20px;
}

[data-popup-video]
{
  cursor: pointer;
}

/* IE10/11 workaround
   By default they use hidden and therefore cut off our arrow
   and fontawesome symbols if they overlow 1px
*/
button
{
  overflow: visible;
}

.richdoc--toplevel:empty
{
  display: none;
}



/* Experiment for the next site?

   NOTE: because there's no 'first-of-class' selector we need to use custom elements

.content-richdoc:first-of-type > *:first-child
{
  margin-top: 0;
}

.content-richdoc:first-of-type > p.normal:last-child
.content-richdoc:first-of-type > ul.unordered:last-child
.content-richdoc:first-of-type > ol.ordered:last-child
.content-richdoc:first-of-type > blockquote:last-child
{
  margin-bottom: 0;
}
*/

  .richdoc > *:first-child
, .wh-form__richtext > *:first-child
{
  margin-top: 0;
}
  .richdoc > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}

.richdoc > p.normal
{
  overflow: hidden;
  text-overflow: ellipsis;
}

/* we don't want to cancel the margin-bottom
   if there's a successive richdoc. (for example as webpack wrapper or embedded object which opens a new container)
*/

/* sort of collapse-margins because we use padding instead of margins */

  .richdoc > .embeddedobject + p
, .wh-form__richtext > .embeddedobject + p
{
  margin-top: 15px; /* top so we can remove if needed in a + selector */
}


/* have listitems stay in line with the rest of the content,
   but also keep the content behind the symbol next to the symbol
   (instead of wrapping around the symbol which happens with list-style: inside)
*/
ul.unordered
{
  padding: 0;
}
/*
FIXME: doesn't work correctly with nested elements in the li content;

ul.unordered li
{
  list-style: none;
}
ul.unordered li
{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
ul.unordered li::before
{
  display: inline-block;
  content: "\2022";
  margin-right: 5px;
}
*/
  ul.unordered li + li
, ol.ordered li + li
{
  margin-top: 5px;
}


/* prevent image in columns or in fluid mobile layout
   to stick outside their content area
*/
img.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

img.wh-rtd__img--floatleft
{
  float: left;
}

img.wh-rtd__img--floatright
{
  float: right;
}

  img.wh-rtd__img--floatleft
, img.wh-rtd__img--floatright
{
  max-width: 50%;
  margin: 0 20px 0 0;
}




/* prevent embeddedobject getting beside a floating image */
  .embeddedobject
, .wh-rtd-embeddedobject
{
  clear: both;
}

/* NOTE: this will give a problem when there are floats in another column
         (outside this element)
         (Unless .richdoc get's an overflow: auto/hidden/scroll; or float: left; so it'll make a new context)
         (essecially have some kind of layout context)
*/
.richdoc::after
{
  display: block;
  content: "";
  clear: both;
}
